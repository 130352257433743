import { AuthenticationProvider } from "@artifactlabs/react-auth";
import { ReactNode } from "react";
import { useRecoilState } from "recoil";
import { useIsClient } from "usehooks-ts";

import authAtom from "@/recoil/auth";
import { getOrgAuth0ConfigByTenantId } from "@/utils/org";

export interface Props {
  tenantId: string;
  children?: ReactNode;
}

const AppAuthenticationProvider: React.FunctionComponent<Props> = ({ tenantId, children }) => {
  const [authState, setAuthState] = useRecoilState(authAtom);
  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }

  const { domain, clientId } = getOrgAuth0ConfigByTenantId(tenantId);

  return (
    <>
      {clientId && domain ? (
        <AuthenticationProvider
          clientId={clientId}
          domain={domain}
          redirectUrl={`${window.location.origin}/callback`}
          onRedirectCallback={appState => {
            setAuthState({
              ...authState,
              returnTo:
                appState && appState.returnTo
                  ? appState.returnTo
                  : `${window.location.pathname}${window.location.search}`,
            });
          }}
        >
          {children}
        </AuthenticationProvider>
      ) : (
        <></>
      )}
    </>
  );
};

export default AppAuthenticationProvider;
