import HKPhilLogo from "@public/general/logos/hkphil.jpg";
import MAPLogo from "@public/general/logos/map.jpg";
import NGSLogo from "@public/general/logos/ngs.svg";
import SCMPLogo from "@public/general/logos/scmp.png";
import SNMLogo from "@public/general/logos/snm.png";
import SZABOTAGELogo from "@public/general/logos/szabotage.jpg";
import TitanicLogo from "@public/general/logos/titanic.svg";
import USNLogo from "@public/general/logos/usn.png";

export const getSettingsByTenantId = (tenantId: string) => {
  if (tenantId.includes("general")) {
    return {
      withLogo: false,
      defaultCurrency: "USD",
    };
  } else if (tenantId.includes("ngs")) {
    return {
      withLogo: true,
      height: 32,
      iconPath: NGSLogo,
      defaultCurrency: "USD",
    };
  } else if (tenantId.includes("titanic")) {
    return {
      withLogo: true,
      width: 100,
      iconPath: TitanicLogo,
      defaultCurrency: "USD",
    };
  } else if (tenantId.includes("scmp")) {
    return {
      withLogo: true,
      width: 150,
      iconPath: SCMPLogo,
      defaultCurrency: "USD",
    };
  } else if (tenantId.includes("usn")) {
    return {
      withLogo: true,
      width: 32,
      iconPath: USNLogo,
      defaultCurrency: "USD",
    };
  } else if (tenantId.includes("platform")) {
    return {
      withLogo: false,
      defaultCurrency: "USD",
    };
  } else if (tenantId.includes("snm")) {
    return {
      withLogo: true,
      width: 32,
      iconPath: SNMLogo,
      defaultCurrency: "USD",
    };
  } else if (tenantId.includes("hkphil")) {
    return {
      withLogo: true,
      width: 60,
      iconPath: HKPhilLogo,
      defaultCurrency: "HKD",
    };
  } else if (tenantId.includes("map")) {
    return {
      withLogo: true,
      width: 60,
      iconPath: MAPLogo,
      defaultCurrency: "USD",
    };
  } else if (tenantId.includes("szabotage")) {
    return {
      withLogo: true,
      width: 60,
      iconPath: SZABOTAGELogo,
      defaultCurrency: "USD",
    };
  } else {
    return {
      withLogo: false,
      defaultCurrency: "USD",
    };
  }
};

export const getTenantConfigs = (tenantId: string) => {
  return {
    settings: getSettingsByTenantId(tenantId),
  };
};
