import { FunctionComponent, ReactNode } from "react";
import { useSetRecoilState } from "recoil";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

import tenantAtom from "@/recoil/tenant";
import { getTenantConfigs } from "@/utils/tenant";

interface Props {
  tenantId: string;
  children: ReactNode;
}

const TenantProvider: FunctionComponent<Props> = ({ tenantId, children }) => {
  const setTenant = useSetRecoilState(tenantAtom);

  useIsomorphicLayoutEffect(() => {
    setTenant(prev =>
      Object.assign({}, prev, {
        id: tenantId,
        ...getTenantConfigs(tenantId),
      }),
    );
  }, [setTenant, tenantId]);

  return children;
};

export default TenantProvider;
