import { atom } from "recoil";

export interface State {
  displayModal: boolean;
  displayDrawer: boolean;
  modalView: string;
  drawerView: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalProps: any;
  isClosable?: boolean;
  drawerProps: Record<string, unknown>;
}

const initialState: State = {
  displayModal: false,
  displayDrawer: false,
  modalView: "GENERIC_MODAL",
  drawerView: "HOME_NAVIGATION",
  modalProps: {},
  drawerProps: {},
};

const UIAtom = atom({
  key: "UIAtom",
  default: initialState,
});

export default UIAtom;
