export interface OrganizationAuth0Config {
  domain: string;
  clientId: string;
}

type TenantConfigMap = {
  [key: string]: {
    domain: string;
    clientId: string;
  };
};

export const TENANT_AUTH0_CONFIG: TenantConfigMap = {
  scmp: {
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN_SCMP ?? "",
    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_SCMP ?? "",
  },
  default: {
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN_DEFAULT ?? "",
    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_DEFAULT ?? "",
  },
} as const;

export const getOrgAuth0ConfigByTenantId = (
  tenantId: string | undefined,
): OrganizationAuth0Config => {
  if (!tenantId) return { domain: "", clientId: "" };

  const config = TENANT_AUTH0_CONFIG[tenantId?.toLowerCase()] || TENANT_AUTH0_CONFIG.default;

  if (!config.domain || !config.clientId) {
    throw new Error(`Missing Auth0 configuration for tenant: ${tenantId}`);
  }

  return config;
};
