import { clsMerge } from "@artifactlabs/shared-react-utils";
import Image from "next/image";
import { FunctionComponent, ReactNode } from "react";
import { useWindowSize } from "usehooks-ts";

import { poppins } from "@/utils/font";
import ArtifactlabsLogo from "@public/general/logos/artifactlabs.png";

interface Props {
  children: ReactNode;
}

export const ServiceUnavailableBlocker: FunctionComponent<Props> = ({ children }) => {
  const { width } = useWindowSize(); // TODO: change to useMediaQuery after isSafari is not needed

  if (!width) {
    return null;
  }

  if (width < 1024) {
    return (
      <div className={"relative! block px-6"}>
        <div className="flex min-h-screen flex-1 flex-col justify-center">
          <div className="flex items-center justify-center">
            <Image
              alt=""
              className="min-h-[40px] min-w-[40px]"
              height={100}
              src={ArtifactlabsLogo}
              width={100}
              unoptimized
            />
          </div>
          <p className={clsMerge("mt-8 text-center font-bold", poppins.className)}>
            Unavailable on your browser or device
          </p>
          <p className={clsMerge("mt-4 text-center text-sm font-medium", poppins.className)}>
            For the best user experience, please access the platform via Google Chrome on a desktop
            device.
          </p>
        </div>
      </div>
    );
  }

  if (!width) {
    return null;
  }

  return <div className="hidden lg:block">{children}</div>;
};
