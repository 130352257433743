import { NextJsTracking, NextJsNoScriptTracking } from "@artifactlabs/shared-react-utils";
import { AnimatePresence } from "framer-motion";
import type { AppProps } from "next/app";
import { useParams } from "next/navigation";
import { useMemo } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot } from "recoil";

import { type NextPageWithLayout } from "./page";

import AppAuthenticationProvider from "@/components/Authentication/AppAuthenticationProvider";
import ApolloContextProvider from "@/components/Context/ApolloContextProvider";
import ServiceUnavailableBlocker from "@/components/Pages/ServiceUnavailableBlocker";
import TenantProvider from "@/components/TenantProvider";
import "@/styles/globals.css";

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

interface AppPropsWithLayout extends AppProps {
  Component: NextPageWithLayout;
}

const App = ({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) => {
  const params = useParams();
  const subdomain = useMemo(() => (params?.subdomain as string) ?? "", [params]);
  const getLayout = Component.getLayout || (page => page);

  return (
    <>
      <NextJsNoScriptTracking gaMeasurementId={GA_MEASUREMENT_ID} />
      <NextJsTracking gaMeasurementId={GA_MEASUREMENT_ID} />
      <RecoilRoot>
        <TenantProvider tenantId={subdomain}>
          <AppAuthenticationProvider tenantId={subdomain}>
            <ApolloContextProvider tenantId={subdomain}>
              <AnimatePresence mode="wait">
                <ServiceUnavailableBlocker>
                  {getLayout(<Component {...pageProps} />)}
                </ServiceUnavailableBlocker>
              </AnimatePresence>
              <ToastContainer />
            </ApolloContextProvider>
          </AppAuthenticationProvider>
        </TenantProvider>
      </RecoilRoot>
    </>
  );
};

export default App;
