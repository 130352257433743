import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  Observable,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuthState } from "@artifactlabs/react-auth";
import { useRouter } from "next/navigation";
import React, { ReactElement, ReactNode, useMemo } from "react";

import { useOpenModal } from "@/hooks/useModal";
import { getOrgAuth0ConfigByTenantId } from "@/utils/org";

const GQL_ENDPOINT = process.env.NEXT_PUBLIC_GQL_ENDPOINT;

export default function Provider({
  children,
  tenantId,
}: {
  children: ReactNode;
  tenantId: string;
}): ReactElement {
  const { domain } = getOrgAuth0ConfigByTenantId(tenantId);
  const { getAccessTokenWithoutChecking } = useAuthState(domain);
  const openModal = useOpenModal();
  const router = useRouter();

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: GQL_ENDPOINT,
    });

    const tenantIdLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          "x-tenant-id": tenantId,
        },
      };
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenWithoutChecking(false);
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    const getErrorLink = (errorCallback: Function) =>
      new ApolloLink((operation, forward) => {
        return new Observable(observer => {
          const observable = forward(operation);
          const subscription = observable.subscribe({
            next(value) {
              observer.next(value);
            },
            error(networkError) {
              errorCallback({ networkError, operation });
            },
            complete() {
              observer.complete();
            },
          });

          return () => subscription.unsubscribe();
        });
      });

    const chainedLink = ApolloLink.from([
      getErrorLink(() => {
        openModal("GENERIC_MODAL", {
          status: "error",
          title: "Something went wrong",
          description: "Oops, something went wrong. Please try again later!",
          mainActionText: "OK",
          onConfirm: () => router.refresh(),
        });
      }),
      authLink,
      tenantIdLink,
      httpLink,
    ]);

    return new ApolloClient({
      link: chainedLink,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              requests: {
                // Don't cache separate results based on
                // any of this field's arguments.
                keyArgs: false,

                merge(existing, incoming) {
                  return {
                    ...incoming,
                    paginatedResults: existing?.paginatedResults?.concat(incoming.paginatedResults),
                  };
                },
              },
            },
          },
        },
      }),
      connectToDevTools: process.env.APP_ENV !== "production",
    });
  }, [getAccessTokenWithoutChecking, openModal, router, tenantId]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
