import { atom } from "recoil";

export interface State {
  isAdmin: boolean;
  isLoading: boolean;
  returnTo?: string;
}

const initialState: State = {
  isAdmin: false,
  isLoading: true,
};

export const authAtom = atom({
  key: "AuthAtom",
  default: initialState,
});
