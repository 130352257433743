"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var gtmCustomEvent = function (_a) {
    var action = _a.action, category = _a.category, label = _a.label, value = _a.value;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "custom.".concat(action),
        category: category,
        label: label,
        value: value,
    });
};
exports.default = gtmCustomEvent;
