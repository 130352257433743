"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clsMerge = void 0;
/* eslint-disable @typescript-eslint/no-restricted-imports */
var clsx_1 = require("clsx");
var tailwind_merge_1 = require("tailwind-merge");
var clsMerge = function () {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return (0, tailwind_merge_1.twMerge)(clsx_1.clsx.apply(void 0, classes));
};
exports.clsMerge = clsMerge;
